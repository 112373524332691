<template>
  <div class="container">
    <div class="pa-20">
      <img class="logo" src="~@/assets/img/logo.png" />
      <div class="content flex f-center flex-direction f-jcenter">
        <img class="head" src="~@/assets/img/success.png" />
        <div class="font-bold t-center fon-18 ma-tb-30">确认成功</div>
        <div class="width60" @click="toPage">
          <van-button type="primary" class="width100" color="#003AA9"
            >回到首页</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {};
  },
  created() {
    _this = this;
    _this.getCode()

  },
  methods: {
    toPage() {
      _this.$router.replace("/");
    },
    //获取扫码枪
    getCode() {
      _this
        .$request({
          url: "/security/code",
          method: "GET",
          params: {
            eid: _this.$store.state.entranceId,
          },
        })
        .then((result) => {
          //避免重复扫码的情况
          let ids = localStorage.getItem("ids");
          if (ids && result) {
            ids = JSON.parse(ids);
            var findIndex = ids.indexOf(result);
            if (findIndex != -1) {
              _this.getCode();
              return
            }
          }
          // alert(result);
          if (_this.$validator.isEmpty(result)) {
            localStorage.removeItem("ids")
          } else {
            console.log(result);
            _this.$dialog
              .confirm({
                title: "提示",
                message: "当前访客信息已确认，点击按钮处理下一组访客信息",
                showCancelButton: false,
                confirmButtonText: "继续确认访客信息",
              })
              .then(() => {
                _this.$redirectTo(result);
              });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 476px;
  height: 355px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .head {
    width: 120px;
  }
}
</style>